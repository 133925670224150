import { Form, Formik, useField } from "formik";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import React, { useEffect, useState } from "react";
import { Financeiro } from "../../../financeiro/types/financeiro";
import { novoFinanceiro } from "../../../financeiro/data/financeiro";
import { InputSelectArray } from "../../../sistema/components/InputSelectArray";
import { mensagemErro, mensagemSucesso } from "../../../utils/toasts";
import InputSelect from "../../../sistema/components/InputSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";
import { getTotalFinalCartValue } from "../../utils/cartUtils";
import { getDecimalPlacesOfSystem } from "../../../sistema/utils/getDecimalPlacesOfSystem";
import { formatValor } from "../../../utils/formatValor";
import { Badge, Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import InputNumber from "../../../sistema/components/InputNumber";
import InputField from "../../../sistema/components/InputField";
import { formatDataObject, getCurrentData } from "../../../utils/data";
import { novoFinanceiroParcela } from "../../../financeiro/data/financeiro_parcela";
import { addMonths, isBefore, parse } from "date-fns";
import { FinanceiroParcela } from "../../../financeiro/types/financeiro_parcela";
import ResponsiveTable, {
  TableHeaders,
} from "../../../sistema/components/ResponsiveTable";
import { PedidoItem } from "../../../entradaesaida/types/pedido_item";
import { ShowArray } from "../../../sistema/components/ShowArray";
import { FiTrash } from "react-icons/fi";
import { ConfirmationModal } from "../../../sistema/components/ConfirmationModal";
import { SecondaryButton } from "../../../sistema/components/SecondaryButton";
import { DangerButton } from "../../../sistema/components/DangerButton";

type PaymentInformationProps = {
  isOpen: boolean;
  onClose: () => void;
  onFinish: () => void;
};

export const PaymentInformation = ({
  isOpen,
  onClose,
  onFinish,
}: PaymentInformationProps) => {
  const [, { value: financeiros }, { setValue: setFinanceiros }] =
    useField<Financeiro[]>("financeiros");

  const [, { value: orderItems }] = useField<PedidoItem[]>("itens");

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);

  const sellers = useSelector((state: RootState) => state.pdv?.sellers ?? []);

  const getTotalPayments = (financeiros: Financeiro[]) => {
    return financeiros.reduce((previus, current) => {
      return previus + parseFloat(current.valor_total);
    }, 0);
  };

  const getTotalToPayYet = (financeiros: Financeiro[]) => {
    return parseFloat(
      (
        getTotalFinalCartValue(orderItems ?? []) -
        getTotalPayments(financeiros ?? [])
      ).toFixed(getDecimalPlacesOfSystem())
    );
  };

  const getDueDate = (day: number) => {
    const currentDay = parseInt(getCurrentData("dd"));

    let returnDate = new Date();

    if (day < currentDay) {
      returnDate = addMonths(returnDate, 1);
      returnDate.setDate(day);

      return formatDataObject(returnDate, "yyyy-MM-dd");
    }

    returnDate.setDate(day);

    return formatDataObject(returnDate, "yyyy-MM-dd");
  };

  const getQuotas = (
    quotaQuantity: number,
    paymentDay: number,
    value: number
  ) => {
    if (paymentDay > 31 || paymentDay <= 0) {
      mensagemErro("Deve ser informado um dia de vencimento válido!");
      return;
    }

    let dataInicial = parse(`${paymentDay}`.padStart(2, "0"), "dd", new Date());
    if (isBefore(dataInicial, new Date())) {
      dataInicial = addMonths(dataInicial, 1);
    }
    const parcelasNovas: FinanceiroParcela[] = [];
    for (let i = 0; i < quotaQuantity; i++) {
      const isLast = i === quotaQuantity - 1;

      let quotaValue: any = (value / quotaQuantity).toFixed(
        getDecimalPlacesOfSystem()
      );

      if (isLast) {
        const difference = value - parseFloat(quotaValue) * quotaQuantity;

        if (difference !== 0) {
          quotaValue = parseFloat(
            (parseFloat(quotaValue) + difference).toFixed(
              getDecimalPlacesOfSystem()
            )
          );
        }
      }

      parcelasNovas.push({
        ...novoFinanceiroParcela(),
        codigo: `${1 + i}`.padStart(3, "0"),
        data_vencimento: formatDataObject(
          addMonths(dataInicial, i),
          "yyyy-MM-dd"
        ),
        valor: quotaValue as any,
        changed: true,
      });
    }

    return parcelasNovas;
  };

  const newFinancial = (financeiros: Financeiro[]): Financeiro => ({
    ...novoFinanceiro("receber"),
    par: "pedido",
    tipo_pagamento: 0,
    _condicao_pagamento: "avista",
    _valor_pagamento: getTotalToPayYet(financeiros),
    _valor_troco: 0,
    _data_pagamento: getCurrentData("yyyy-MM-dd"),
    _quantidade_parcelas: 1,
    _dia_pagamento: parseInt(getCurrentData("dd")),
    changed: true,
    parcelas: [
      {
        ...novoFinanceiroParcela(),
        codigo: "001",
        data_vencimento: getDueDate(parseInt(getCurrentData("dd"))),
        valor: getTotalToPayYet(financeiros) as any,
        valor_troco: 0 as any,
      },
    ],
  });

  const [currentItem, setCurrentItem] = useState<Financeiro | null>(
    newFinancial(financeiros ?? [])
  );

  const {
    isOpen: isOpenRevision,
    onOpen: onOpenRevision,
    onClose: onCloseRevision,
  } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      setCurrentItem(newFinancial(financeiros ?? []));

      if (getTotalToPayYet(financeiros ?? []) == 0) {
        onOpenRevision();
      } else {
        onCloseRevision();
      }
    }
  }, [isOpen]);

  const [itemIndexToBeingDeleted, setItemIndexToBeingDeleted] =
    useState<number>(-1);
  const deleteItem = () => {
    const values: Financeiro[] = JSON.parse(JSON.stringify(financeiros ?? []));

    values.splice(itemIndexToBeingDeleted, 1);

    setFinanceiros(values);

    setItemIndexToBeingDeleted(-1);
  };

  const headers: TableHeaders<Financeiro>[] = [
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <div className="flex items-center">
          <button
            type="button"
            className="text-red-700 hover:bg-red-100 rounded-md p-1"
            onClick={() => setItemIndexToBeingDeleted(index)}
          >
            <FiTrash />
          </button>
        </div>
      ),
      notClicable: true,
    },
    {
      label: "Método de Pagamento",
      wrapped: true,
      render: (item) => (
        <ShowArray
          arrayName="financeiro_tipo_pagamento"
          value={item.tipo_pagamento * 1}
        />
      ),
      notClicable: true,
    },
    {
      label: "Valor Pagamento",
      wrapped: true,
      render: (item) =>
        `R$ ${formatValor(item.valor_total, getDecimalPlacesOfSystem())}`,
      notClicable: true,
    },
    {
      label: "Parcelas",
      wrapped: true,
      render: (item) =>
        `${(item?.parcelas ?? []).length} ${
          (item?.parcelas ?? []).length === 1 ? "parcela" : "parcelas"
        }`,
      notClicable: true,
    },
  ];

  return (
    <DialogModal
      show={isOpen}
      close={onClose}
      maxWidth="2xl"
      title={
        <ListHeaderSecondary>
          {isOpenRevision
            ? "Revisão dos pagamentos"
            : "Informações sobre pagamento"}
          <DefaultButton size={"sm"} onClick={onClose} ml={2}>
            Voltar
          </DefaultButton>
          {(financeiros ?? []).length > 0 && (
            <Button
              colorScheme="red"
              size={"sm"}
              type="button"
              onClick={() => {
                setFinanceiros([]);
                setCurrentItem(newFinancial([]));
              }}
              ml={2}
            >
              Limpar Pagamentos
            </Button>
          )}
        </ListHeaderSecondary>
      }
      content={
        <div className="w-full flex flex-wrap">
          <div className="w-full">
            <ConfirmationModal
              show={itemIndexToBeingDeleted !== -1}
              close={() => setItemIndexToBeingDeleted(-1)}
              title={"Remover Pagamento"}
              content={"Você tem certeza que deseja remover este pagamento?"}
              footer={
                <React.Fragment>
                  <SecondaryButton
                    externalClass="w-auto"
                    onClick={() => setItemIndexToBeingDeleted(-1)}
                  >
                    Cancelar
                  </SecondaryButton>

                  <DangerButton externalClass="ms-3" onClick={deleteItem}>
                    Remover
                  </DangerButton>
                </React.Fragment>
              }
            />

            {isOpenRevision && (
              <div className="w-full flex flex-wrap">
                <ResponsiveTable
                  isSmall
                  headers={headers}
                  data={financeiros ?? []}
                />

                <div className="w-full border-b mt-4 mb-1"></div>

                <div className="w-full flex justify-between py-2 font-semibold">
                  <span>Total da Venda: </span>
                  <span className="text-green-700">
                    R${" "}
                    {formatValor(
                      getTotalFinalCartValue(orderItems ?? []),
                      getDecimalPlacesOfSystem()
                    )}
                  </span>
                </div>

                <div className="w-full flex justify-between py-2 font-semibold">
                  <span>Total do Pagamento: </span>
                  <span
                    className={
                      getTotalPayments(financeiros ?? []) !=
                      getTotalFinalCartValue(orderItems ?? [])
                        ? "text-red-700"
                        : "text-green-700"
                    }
                  >
                    R${" "}
                    {formatValor(
                      getTotalPayments(financeiros ?? []),
                      getDecimalPlacesOfSystem()
                    )}
                  </span>
                </div>

                <div className="w-full border-b mt-4 mb-1"></div>

                <InputSelect
                  label="Vendedor"
                  name="seller_id"
                  width={"full"}
                  noPlaceholer
                >
                  <option value="">Selecione...</option>
                  {sellers.map((seller) => (
                    <option value={seller.id}>
                      {seller.code} - {seller.name}
                    </option>
                  ))}
                </InputSelect>

                <Flex width={"full"} />
                {getTotalToPayYet(financeiros ?? []) > 0 && (
                  <Box
                    bg={"white"}
                    mt={2}
                    width={"full"}
                    position="sticky"
                    bottom="0px"
                    zIndex="10"
                    py={2}
                  >
                    <DefaultButton
                      type="button"
                      onClick={() => {
                        setCurrentItem(newFinancial(financeiros ?? []));
                        onCloseRevision();
                      }}
                      className="w-full mt-1"
                    >
                      Adicionar Pagamento
                    </DefaultButton>
                  </Box>
                )}

                {getTotalToPayYet(financeiros ?? []) == 0 && (
                  <Box
                    bg={"white"}
                    mt={2}
                    width={"full"}
                    position="sticky"
                    bottom="0px"
                    zIndex="10"
                    py={2}
                  >
                    <DefaultButton
                      type="button"
                      onClick={onFinish}
                      className="w-full mt-1"
                    >
                      Finalizar Venda
                    </DefaultButton>
                  </Box>
                )}
              </div>
            )}
            {currentItem && !isOpenRevision && (
              <Formik
                enableReinitialize
                initialValues={currentItem}
                onSubmit={(values, { setErrors }) => {
                  if (!values.tipo_pagamento) {
                    setErrors({
                      tipo_pagamento: "Selecione o método de pagamento.",
                    });
                    mensagemErro(
                      "É necessário selecionar o método de pagamento para continuar."
                    );
                    return;
                  }

                  if ((values?._valor_pagamento ?? 0) <= 0) {
                    setErrors({
                      tipo_pagamento: "O valor precisa ser maior que zero.",
                    });
                    mensagemErro("O valor precisa ser maior que zero.");
                    return;
                  }

                  if (values._condicao_pagamento === "avista") {
                    if (!values._data_pagamento) {
                      setErrors({
                        tipo_pagamento: "Defina a data do pagamento.",
                      });
                      mensagemErro("Defina a data do pagamento.");
                      return;
                    }

                    values.parcelas = [
                      {
                        ...novoFinanceiroParcela(),
                        codigo: "001",
                        data_vencimento: values._data_pagamento,
                        valor: values._valor_pagamento as any,
                        valor_troco: values._valor_troco as any,
                        _valor_baixa: values._valor_pagamento as any,
                        _data_baixa: values._data_pagamento as any,
                      },
                    ];

                    values.valor_total = values._valor_pagamento as any;
                  }

                  if (values._condicao_pagamento === "aprazo") {
                    if ((values?.parcelas ?? []).length === 0) {
                      mensagemErro("Coloque ao menos 1 parcela.");
                      return;
                    }

                    const valorPagamento = values?._valor_pagamento ?? 0;
                    const valorParcelas = (values?.parcelas ?? []).reduce(
                      (previusValue, currentValue) => {
                        return (previusValue +
                          parseFloat(currentValue.valor)) as any;
                      },
                      0
                    );
                    if (
                      valorPagamento.toFixed(getDecimalPlacesOfSystem()) !=
                      valorParcelas.toFixed(getDecimalPlacesOfSystem())
                    ) {
                      mensagemErro(
                        "O valor do pagamento não condiz com o valor total somado das parcelas!"
                      );
                      return;
                    }

                    values.valor_total = valorParcelas.toFixed(
                      getDecimalPlacesOfSystem()
                    ) as any;
                  }

                  values.data_emissao = getCurrentData();
                  values.descricao =
                    "Gerado automaticamente ao lançar uma venda.";

                  if (
                    parseFloat(values.valor_total) >
                    parseFloat(
                      getTotalToPayYet(financeiros ?? []).toFixed(
                        getDecimalPlacesOfSystem()
                      )
                    )
                  ) {
                    mensagemErro(
                      `O valor do pagamento é maior do que falta pagar, total a pagar: ${formatValor(
                        getTotalToPayYet(financeiros ?? []),
                        getDecimalPlacesOfSystem()
                      )} `
                    );
                    return;
                  }

                  const currentFinanceiros = JSON.parse(
                    JSON.stringify(financeiros ?? [])
                  );

                  const updatedFinanceiros: Financeiro[] = [
                    ...currentFinanceiros,
                    {
                      ...values,
                    },
                  ];
                  mensagemSucesso("Pagamento registrado!");
                  setFinanceiros(updatedFinanceiros);

                  const valorTotalGeralAtualizado = updatedFinanceiros.reduce(
                    (previus, current) => {
                      return (previus + parseFloat(current.valor_total)) as any;
                    },
                    0
                  );

                  console.log(
                    valorTotalGeralAtualizado,
                    updatedFinanceiros,
                    getTotalFinalCartValue(orderItems ?? []),
                    orderItems
                  );

                  if (
                    valorTotalGeralAtualizado ==
                    getTotalFinalCartValue(orderItems ?? [])
                  ) {
                    onOpenRevision();
                    setCurrentItem(null);
                    return;
                  }

                  setCurrentItem(newFinancial(updatedFinanceiros));
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div className="w-full flex flex-wrap">
                      <div className="w-full flex flex-wrap justify-center text-center">
                        <Badge color={"blue.500"} mx={2}>
                          VENDA <br /> R${" "}
                          {formatValor(
                            getTotalFinalCartValue(orderItems ?? []),
                            getDecimalPlacesOfSystem()
                          )}
                        </Badge>

                        <Badge color={"blue.500"} mx={2}>
                          PAGAMENTOS ({(financeiros ?? []).length}) <br /> R${" "}
                          {formatValor(
                            getTotalPayments(financeiros ?? []),
                            getDecimalPlacesOfSystem()
                          )}
                        </Badge>

                        <Badge color={"green.500"} mx={2}>
                          VENDA - PAGAMENTOS <br /> R${" "}
                          {formatValor(
                            getTotalToPayYet(financeiros ?? []),
                            getDecimalPlacesOfSystem()
                          )}
                        </Badge>
                      </div>
                      <div className="w-full flex flex-wrap">
                        <InputSelectArray
                          width={"full"}
                          label="Método de Pagamento"
                          arrayName="financeiro_tipo_pagamento"
                          name="tipo_pagamento"
                        />
                        <div className="w-full my-2"></div>
                        <InputSelect
                          label="Condição de pagamento"
                          name="_condicao_pagamento"
                          width={isMobile ? "100%" : "33.33%"}
                          noPlaceholer
                        >
                          <option value="avista">À vista</option>
                          <option value="aprazo">À prazo</option>
                        </InputSelect>
                        <InputNumber
                          decimalPlaces={getDecimalPlacesOfSystem()}
                          label="Valor do pagamento"
                          width={
                            isMobile
                              ? "100%"
                              : values._condicao_pagamento === "avista"
                              ? "33.33%"
                              : "66.66%"
                          }
                          name="_valor_pagamento"
                          onChangeVal={(val) => {
                            if (values._condicao_pagamento === "avista") {
                              setFieldValue(
                                "_valor_troco",
                                val - getTotalToPayYet(financeiros ?? []) > 0
                                  ? val - getTotalToPayYet(financeiros ?? [])
                                  : 0
                              );
                            }

                            setFieldValue(
                              "parcelas",
                              getQuotas(
                                values._quantidade_parcelas ?? 0,
                                values._dia_pagamento ?? 0,
                                val
                              )
                            );
                          }}
                        />

                        {values._condicao_pagamento === "avista" && (
                          <React.Fragment>
                            <InputNumber
                              decimalPlaces={getDecimalPlacesOfSystem()}
                              disabled
                              label="Valor do troco"
                              width={isMobile ? "100%" : "33.33%"}
                              name="_valor_troco"
                            />
                            <InputField
                              label="Data do pagamento"
                              name="_data_pagamento"
                              width={isMobile ? "100%" : "33.33%"}
                              type="date"
                            />
                          </React.Fragment>
                        )}

                        {values._condicao_pagamento === "aprazo" && (
                          <React.Fragment>
                            <InputNumber
                              decimalPlaces={0}
                              label="Quantidade de parcelas"
                              width={isMobile ? "100%" : "50%"}
                              name="_quantidade_parcelas"
                              onChangeVal={(quotaQuantity) => {
                                setFieldValue(
                                  "parcelas",
                                  getQuotas(
                                    quotaQuantity,
                                    values._dia_pagamento ?? 0,
                                    values._valor_pagamento ?? 0
                                  )
                                );
                              }}
                            />
                            <InputNumber
                              decimalPlaces={0}
                              label="Dia de vencimento"
                              width={isMobile ? "100%" : "50%"}
                              name="_dia_pagamento"
                              onChangeVal={(diaPagamento) => {
                                setFieldValue(
                                  "parcelas",
                                  getQuotas(
                                    values._quantidade_parcelas ?? 0,
                                    diaPagamento,
                                    values._valor_pagamento ?? 0
                                  )
                                );
                              }}
                            />

                            <ResponsiveTable
                              isSmall
                              headers={
                                [
                                  {
                                    label: "#",
                                    widthDesktop: "15%",
                                    render: (item, index) => item.codigo,
                                  },
                                  {
                                    label: "Vencimento",
                                    widthDesktop: "30%",
                                    render: (item, index) => (
                                      <InputField
                                        disabled={item.status == 2}
                                        type="date"
                                        label=""
                                        name={`parcelas[${index}].data_vencimento`}
                                      />
                                    ),
                                  },
                                  {
                                    label: `Valor (R$ ${formatValor(
                                      (values?.parcelas ?? []).reduce(
                                        (previusValue, currentValue) => {
                                          return (previusValue +
                                            parseFloat(
                                              currentValue.valor
                                            )) as any;
                                        },
                                        0
                                      ),
                                      getDecimalPlacesOfSystem()
                                    )})`,
                                    widthDesktop: "30%",
                                    render: (item, index) => (
                                      <InputNumber
                                        disabled={item.status == 2}
                                        name={`parcelas[${index}].valor`}
                                        label=""
                                        width={"full"}
                                        decimalPlaces={getDecimalPlacesOfSystem()}
                                      />
                                    ),
                                  },
                                ] as TableHeaders<FinanceiroParcela>[]
                              }
                              data={values.parcelas ?? []}
                            />
                          </React.Fragment>
                        )}
                      </div>
                      <Flex width={"full"} />
                      <Box
                        bg={"white"}
                        mt={2}
                        width={"full"}
                        position="sticky"
                        bottom="0px"
                        zIndex="10"
                        p={2}
                      >
                        <DefaultButton type="submit" className="w-full mt-1">
                          Adicionar Pagamento
                        </DefaultButton>
                      </Box>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      }
    />
  );
};
