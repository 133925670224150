import { addDays, format, parse, parseISO, subDays } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

const parseStringDate = (dateString: string): Date => {
  return parseISO(dateString);
};

export const formatData = (
  data: string,
  template = "dd/MM/yyyy HH:mm"
) => {
  if (!data) {
    return "";
  }

  return format(parseStringDate(data), template, { locale: ptBrLocale });
};

export const getCurrentData = (template = "yyyy-MM-dd HH:mm") => {
  return format(new Date(), template, { locale: ptBrLocale });
};

export const formatDataObject = (data: Date, template = "yyyy-MM-dd HH:mm") => {
  return format(data, template, { locale: ptBrLocale });
};

export const getDataMenosDias = (dias = 30, template = "yyyy-MM-dd") => {
  const dataRef = subDays(new Date(), dias);
  return format(dataRef, template, { locale: ptBrLocale });
};

export const getDataMaisDias = (dias = 30, template = "yyyy-MM-dd") => {
  const dataRef = addDays(new Date(), dias);
  return format(dataRef, template, { locale: ptBrLocale });
};

export const parseData = (data: string, template = 'dd/MM/yyyy'): string => {
  if (!data) return '';
  return format(parseISO(data), template);
};